.account-card {
  margin-right: 25px;
  overflow: hidden;
  min-width: 250px;
  cursor: pointer;
  color: #91989e;

  dl,
  dt,
  dd {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
  }

  dl {
    display: flex;

    &.alt {
      background: rgba(255, 255, 255, 0.3);
    }
  }

  dt,
  dd {
    padding: 8px 8px;
    line-height: 1;
  }

  .title {
    line-height: 1;
    font-size: 20px;
    margin: 0 0 6px 0;
  }
  .subtitle {
    line-height: 1;
    font-weight: 300;
    font-size: 14px;
    margin: 0;
  }
  .details {
    border: 1px solid #91989e;
    border-radius: 2rem;
    font-size: 10px;
    line-height: 1;
    padding: 2px 5px;
  }
  .content,
  .account-details {
    padding: 10px;
  }
  .more {
    box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.25);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;

    svg {
      width: 20px;
      height: 12px;
    }
  }

  &.active {
    color: #fff;

    .content,
    .account-details {
      background: #649bff;
    }
    .title,
    .subtitle {
      color: #fff;
    }
    .details {
      border: 1px solid #fff;
      background: #fff;
      color: #649bff;
    }
    .more {
      background: rgba(100, 155, 255, 0.6);
    }
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

$blue: #649bff;
$pink: #ff9a89;
$grey: #ebebea;
$dark-grey: #91989e;

/*

  $grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
  ) !default;

  $container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px
  ) !default;

*/

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1440px,
);

$container-max-widths: (
  sm: 768px,
  md: 1200px,
  lg: 1200px,
  xl: 1440px,
);

$theme-colors: (
  "primary": $blue,
);

@import "~bootstrap/scss/bootstrap";
@import "fonts";
@import "mediaqueries";

$rose: $pink;

.current-account {
  color: $dark-grey;
  font-size: 9pt;
}

.bg-grey {
  background-color: $grey;
  color: #91989e;
}

.bg-pink {
  background-color: $pink;
  color: #fff;
}

.bg-blue {
  background-color: $blue;
  color: #fff;
}

.btn-outline-primary,
.btn-outline-primary-sm {
  border-radius: 2em;
  font-size: 10pt;
  font-weight: bold;
  padding: 0.375rem 1.75rem;
}

.btn-sm {
  padding: 0.1rem 0.75rem;
}

.btn-round {
  border: 1px solid #fff;
  border-radius: 2em;
  color: #fff;
  padding: 4px 20px;
  font-size: 10pt;
  line-height: 1;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.btn-round-blue {
  border-color: $blue;
  color: $blue;
  display: inline-block;
  &:hover {
    color: darken($blue, 10%);
    border-color: darken($blue, 10%);
  }
}

.btn-round-pink {
  border-color: $pink;
  color: $pink;
  display: inline-block;
  &:hover {
    color: darken($pink, 10%);
    border-color: darken($pink, 10%);
  }
}

/*
  Chrome autofill inputs
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/*
  Custom Checkbox
*/
/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.5em;
  height: 1.5em;
  background: $pink;
  border-radius: 50%;
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: "\2713\0020";
  position: absolute;
  top: 0.15em;
  left: 0.22em;
  font-size: 1.3em;
  line-height: 0.8;
  color: #fff;
  transition: all 0.2s;
  font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: 1px dotted darken($pink, 10%);
}

/* hover style just for information */
label:hover:before {
  background-color: darken($rose, 5%);
}

body {
  font-family: Neutro, Arial, Helvetica, sans-serif;

  // @media (min-width: 768px) {
  //   padding-top: 80px;
  // }
}

@media screen and (max-width: 760px) {
  #root {
    background-color: #f9f9f9;
    padding-bottom: 5rem;
  }
}

.content-wrapper {
  padding-top: 100px;
}

@media screen and (max-width: 760px) {
  .content-wrapper {
    padding-top: 2rem;
  }
}

.dbg {
  background-color: #eee;
  border: 1px solid #aaa;
  color: #666;
  font-size: 9pt;
  padding: 8px 8px 20px 8px;
  border-radius: 6px;
  overflow-x: auto;
  max-width: 1200px;
}

.flex-none {
  flex: none;
}

.bg-gray {
  background-color: #898d8d;
}

.bg-white-blue {
  @include mq(lg) {
    background: linear-gradient(
      to right,
      #fff 0%,
      #fff 50%,
      $blue 50%,
      $blue 100%
    );
  }
}

.text-white {
  color: #fff;
}

.btn-pink {
  color: #fff;
  background-color: $rose;
  border-color: $rose;
  border-radius: 2em;
  padding: 1rem 2rem;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    background-color: darken($rose, 5%);
    border-color: darken($rose, 5%);
    color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
}

.form-control-line {
  border: none;
  border-bottom: 4px solid #898d8d;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 20px;
  line-height: 24px;
  color: #898d8d;

  &:focus {
    outline: none;
    box-shadow: none;
    //border-color: lighten(#898d8d, 10%);
    border-color: $blue;
  }
}

.has-errors {
  .form-control-line {
    border-bottom-color: #ed8483;
  }
}

/*
  Main Menu
*/
.main-menu {
  @extend .align-items-center;

  list-style: none;
  margin: 0 0 0 5px;
  padding: 0;

  li {
    margin: 0 20px;
  }
  a {
    color: #91989e;
    text-decoration: none;
    font-size: 15px;
    line-height: 1.3;
    padding-bottom: 5px;
    display: block;

    &.active {
      border-bottom: 4px solid $pink;
      padding-bottom: 1px;
    }
  }
}

/*
  Header
*/
.header-app {
  background-color: #fff;

  @media (min-width: 768px) {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.header-container,
.header-container-app {
  @extend .container-fluid;
  @extend .d-flex;
  @extend .align-items-stretch;
  @extend .justify-content-between;

  min-height: 80px;
}

.header-container-app {
  padding: 0;

  .logo {
    width: 270px;
    justify-content: center;
  }

  .message {
    background-color: rgba(100, 155, 255, 0.1);
    min-width: 80px;
    display: flex;

    a {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 23px;
      height: 16px;
    }
  }
  .notification {
    background-color: #649bff;
    min-width: 80px;
    display: flex;

    a {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 23px;
      height: 23px;
    }

    .bell {
      position: relative;
      display: inline-block;

      em {
        background-color: #fff;
        border: 1px solid #649bff;
        color: #649bff;
        font-style: normal;
        font-size: 11px;
        line-height: 1;
        padding: 2px 4px;
        min-width: 15px;
        text-align: center;
        display: inline-block;
        border-radius: 2em;
        position: absolute;
        right: -4px;
        top: -4px;
      }
    }
  }
}

.logo {
  display: flex;
  align-items: center;

  img {
    max-width: 160px;

    @include mq(lg) {
      max-width: 200px;
    }
  }
}

.back-home {
  background-color: #d9d9d6;
  margin-left: -15px;
  width: 80px;

  @include mq(sm) {
    width: 144px;
  }

  a {
    color: #898d8d;

    &:hover {
      text-decoration: none;
      background-color: darken(#d9d9d6, 5%);
    }
  }
  span {
    margin-left: 15px;
    display: none;

    @include mq(sm) {
      display: block;
    }
  }
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.lang-switch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 80px;
  margin-right: -15px;

  @include mq(sm) {
    margin-right: 0;
  }

  .btn-mobile {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    @include mq(sm) {
      display: none;
    }
  }

  &.is-open {
    align-items: start;

    .btn-mobile {
      display: none;
    }

    .lang-switch {
      background: #fff;
      width: 100%;
      text-align: center;
      z-index: 10;
      padding-top: 7px;
      padding-bottom: 7px;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

      li {
        display: block;
      }
      a {
        display: block;
        padding: 8px 0;
      }
    }
  }
}
.lang-switch {
  font-family: Neutro, Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
  display: block;
  list-style-type: none;
  position: absolute;

  @include mq(sm) {
    display: flex;
    position: static;
  }

  li {
    display: none;

    &.chevron-down {
      display: block;
      text-align: center;

      @include mq(sm) {
        display: none;
      }
    }

    @include mq(sm) {
      display: block;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }

    &.active {
      display: block;
      a {
        color: $rose;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  a {
    color: #898d8d;
    font-weight: 500;
  }
}

.user-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;

  .name {
    font-size: 14px;
    color: #91989e;
    line-height: 1;
    margin-bottom: 4px;
  }

  .btn-round-blue,
  .btn-round-pink {
    border-width: 2px;
    margin-right: 5px;
    font-size: 11px;
    padding: 3px 15px;
  }

  svg {
    width: 24px;
    height: 23px;
    margin-right: 15px;
  }
}

/*
  Footer
*/

footer {
  font-family: Neutro, Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 14px;

  a {
    margin: 0 5px;
  }
}

.app-footer {
  background-color: #ebebea;

  .border-right-white {
    border-right: 1px solid #fff;
  }

  .footer-top {
    padding: 50px 70px 32px 70px;
    min-height: 180px;
  }

  .footer-bot {
    background-color: #ff9a89;
    padding: 8px 70px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    min-height: 70px;

    a {
      color: #fff;
    }
  }

  .footer-title {
    color: #91989e;
    font-size: 14px;
    line-height: 30px;
    border-bottom: 2px solid #91989e;
    font-weight: 400;
  }

  .apps,
  .social {
    margin-left: 10px;
    margin-right: 10px;

    img {
      max-width: 76px;
      height: auto;
    }

    svg {
      width: 34px;
      height: 34px;
    }
  }
}

.footer-menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    margin-right: 20px;
  }
  a {
    color: #91989e;
    font-style: 15px;
    line-height: 23px;
    display: inline-block;
    &.active,
    &:hover {
      text-decoration: none;
      border-bottom: 2px solid #ff9a89;
    }
  }
}

/*
  Login Page
*/
.login-form {
  padding: 3rem 1rem;

  @include mq(lg) {
    padding: 2em 114px 2em 129px;
  }

  .title {
    font-family: Albra;
    font-weight: 600;
    font-size: 40px;
    color: $blue;
    margin-bottom: 60px;
    border-top: 8px solid $blue;
    padding-top: 30px;

    @include mq(lg) {
      font-size: 50px;
    }
  }

  .form-group {
    margin-bottom: 30px;

    label,
    a {
      font-size: 12px;
      color: #898d8d;
    }
  }

  .error {
    font-size: 12px;
    color: #ed8483;
    margin-top: 20px;
    margin-left: 20px;
    opacity: 0;
  }

  .disclaimer {
    font-size: 12px;
    line-height: 1.3;
    margin-top: 40px;
  }

  .has-errors {
    .error {
      opacity: 1;
    }
  }
}

.login-right-col {
  @extend .col;
  @extend .text-white;
  @extend .d-flex;
  @extend .align-items-center;

  background: $blue;

  @include mq(lg) {
    background: transparent;
  }
}

.login-right {
  padding: 3rem 1rem;

  @include mq(lg) {
    padding: 0 0 0 6em;
  }

  .title {
    font-size: 40px;
    font-family: Albra;
    font-weight: 500;
    line-height: 1;

    @include mq(sm) {
      font-size: 40px;
    }
  }

  @include mq(sm) {
    background: $blue;
  }
}

/*
  Sections
*/
.section-title-container {
  display: flex;
  border-bottom: 2px solid #91989e;
  margin-bottom: 0.5rem;
}

.section-title-text {
  color: #91989e;
  font-weight: 600;
}

.section-title {
  color: #91989e;
  font-size: 23px;
  line-height: 1.3;
  padding: 2px 0;
  margin-bottom: 0;

  .account {
    font-size: 9pt;
    color: #bbb;
  }
}

.section-help {
  display: inline-block;
  width: 36px;
  margin-left: 10px;
  cursor: help;
  img {
    width: 100%;
  }
}

.section-lead {
  margin: 0.3rem 0 1.5rem 0;
  font-size: 10pt;
  color: #91989e;
}

/*
  Sidebar
*/
.sidebar {
  width: 100%;
  position: sticky;
  top: 80px;

  .sticky {
    position: sticky;
    top: 80px;
  }

  hr {
    height: 0;
    border-bottom: 2px solid #fff;
  }

  h3 {
    font-size: 12pt;
    font-weight: 700;
  }

  dt,
  dd {
    font-size: 14px;
  }

  section {
    padding: 20px 30px;
  }

  .events {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
    li {
      margin-bottom: 5px;
    }
  }

  .event {
    border: 1px solid #fff;
    border-radius: 2em;
    color: #fff;
    padding: 4px 20px;
    font-size: 10pt;
    display: block;

    &:hover {
      text-decoration: none;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  .all-events {
    background-color: rgba(255, 255, 255, 0.2);
    margin-bottom: 0;
    text-align: center;
  }
}

.sidebar-section-title {
  border-bottom: 2px solid #fff;
  padding-bottom: 4px;
}

/*
  Sidebar Navigation
*/
.side-navigation-panel-select {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
}

.side-navigation-panel-select-option {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2em;
  padding: 4px 20px;
  font-size: 10pt;
}

.side-navigation-panel-select-option-selected {
  font-weight: bold;
}

.side-navigation-panel-select-option-icon {
  display: none;
}

.side-navigation-panel-select-inner {
  list-style: none;
  margin: 0 0 0 20px;
  padding: 0;
}

.side-navigation-panel-select-inner-wrap {
  margin: 5px 0;
}

.side-navigation-panel-select-inner-option {
  padding-left: 15px;
  font-size: 10pt;
  cursor: pointer;
}

.side-navigation-panel-select-inner-option-selected {
  background: url("../images/submenu-active.png") no-repeat 0 3px;
}

/*
  Personal Banker
*/
.personal-banker {
  display: flex;
  color: #fff;
  .avatar {
    max-width: 94px;
    border-radius: 94px;
    overflow: hidden;
    margin-right: 8px;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .name {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 5px;
  }
  .actions {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 56px;
    display: flex;

    @media (min-width: 768px) {
      display: block;
    }

    li {
      margin: 3px 10px 3px 0;
    }

    a {
      color: #fff;
      border: 1px solid #fff;
      border-radius: 2em;
      font-size: 11px;
      display: block;
      text-align: center;
      padding: 2px 10px;
      line-height: 1;

      &:hover {
        text-decoration: none;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
  .status-online,
  .status-offline {
    font-size: 11px;
    font-weight: 300;
    margin-bottom: 5px;
    &::after {
      content: "";
      width: 7px;
      height: 7px;
      display: inline-block;
      background-color: #11d800;
      margin-left: 5px;
      border-radius: 2em;
    }
  }
  .status-offline {
    &::after {
      background-color: #ca0000;
    }
  }
}

/*
  Sidebar Search
*/
.sidebar {
  .upcoming-events {
    min-height: 180px;
  }

  .sidebar-search {
    padding: 15px 30px;

    [type="submit"] {
      width: 20px;
      height: 20px;
      margin: 0;
      padding: 0;
      border: none;
      display: inline-block;

      svg {
        width: 100%;
        height: 100%;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    [type="text"] {
      border: none;
      background: transparent;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .input-group {
      border-bottom: 2px solid #91989e;
    }
  }
}
/*
  Mobile Toolbox
*/
.mobile-toolbox {
  background-color: #91989e;
  padding: 0 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 64px;

  .menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    a {
      color: #fff;
      text-decoration: none;
      font-size: 11px;
      display: flex;
      align-items: center;
      width: 70px;
      text-indent: -999px;
      overflow: hidden;
      margin: 0 4px;

      &.overview {
        background: url("../images/portfolio-overview.png") no-repeat center
          center;
        &.active {
          background-image: url("../images/overview-360-active.png");
        }
      }
      &.analysis {
        background: url("../images/analysis-360.png") no-repeat center center;
        &.active {
          background-image: url("../images/analysis-360-active.png");
        }
      }
      &.trade {
        background: url("../images/trade-360.png") no-repeat center center;
        &.active {
          background-image: url("../images/trade-360-active.png");
        }
      }
      &.transfer {
        background: url("../images/transfer-360.png") no-repeat center center;
        &.active {
          background-image: url("../images/transfer-360-active.png");
        }
      }

      /*
      &.investment {
        background: url("../images/investment-tools.png") no-repeat center center;
        &.active {
          background-image: url("../images/investment-tools-active.png");
        }
      }
      &.profile {
        background: url("../images/profile.png") no-repeat center center;
        &.active {
          background-image: url("../images/profile-active.png");
        }
      }
      */

      &.active {
        background-color: #ebebea;
      }
    }

    li {
      display: flex;
      width: 25%;
      max-width: 74px;

      img {
        margin-bottom: 2px;
        width: auto;
        height: 30px;
      }

      span {
        text-align: center;
        display: block;
      }
    }
  }

  .btn-menu {
    display: inline-block;
    width: 61px;
    height: 61px;
    margin-top: -10px;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

/*
  Mobile Menu
*/
.mobile-menu {
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 1;
  right: -100vw;
  transition: right 400ms;

  .menu-mid {
    background-color: #ebebea;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    a {
      color: #91989e;
      border: 2px solid #91989e;
      border-radius: 2em;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      margin: 0 10px;
      line-height: 1.3;
      padding: 5px;
      max-width: 170px;
    }
  }
  .menu-bottom {
    background-color: #91989e;
    display: flex;
    padding: 10px;
    justify-content: space-between;
  }

  .close {
    background-color: #ff9a89;
    opacity: 1;
    border-radius: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 61px;
    height: 61px;
    position: absolute;
    right: 10px;
    bottom: 12px;
    svg {
      width: 26px;
      height: 26px;
    }
  }
}

.mobile-menu-active {
  opacity: 1;
  right: 0;
}

.mobile-slide-menu {
  margin: 20px;
  padding: 0;
  list-style: none;
  width: 100%;
  position: relative;
  overflow: hidden;
  a {
    color: #91989e;
    font-size: 16px;
    display: block;
    padding: 10px 18px 10px 18px;
    text-decoration: none;
  }
  .has-subnav > a {
    background: url("../images/chevron-right.png") no-repeat right center;
  }
  .back > a {
    background: url("../images/chevron-left.png") no-repeat left center;
  }
  ul {
    background-color: #fff;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 100%;
    transition: left 500ms;
  }
  li {
    &.active {
      > ul {
        left: 0;
      }
    }
  }
}

.title-mobile {
  color: #91989e;
  text-decoration: none;

  span {
    border-bottom: 2px solid #ff9a89;
    display: flex;
    margin: 0 10px 0 20px;

    /*
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    */
  }
}

.table-mbp {
  thead {
    th {
      border-bottom: 1px solid #dee2e6;
      color: #649bff;
    }
    tr.heading-grey {
      th {
        color: #91989e;
      }
    }
  }
  th,
  td {
    font-size: 10pt;
    color: #91989e;
    border: none;

    &.money {
      text-align: right;
    }
  }
  tr.odd {
    td {
      background: rgba(100, 156, 255, 0.2);
    }
  }
}

.empty-result {
  color: #91989e;
  margin: 0;
  text-align: center;
  font-size: 11pt;
  margin: 20px 0;
}

.widget-light {
  background-color: #f9f9f9;
  padding: 20px;
}

.widget-title {
  color: #91989e;
  font-size: 1.25rem;
}

.debug {
  .info {
    background: #eee;
    display: inline-block;
    color: #c4c4c4;
    border-radius: 5px;
    padding: 4px 8px;
    font-size: 9pt;
    line-height: 1;
    font-weight: bold;
    transition: all 200ms;

    &.active {
      border-radius: 5px 5px 0 0;
    }

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  pre {
    font-size: 8pt;
    line-height: 1.5;
    color: #666;
    margin: 0;
    background: #eee;
    padding: 5px 10px;
    border-radius: 0 5px 5px 5px;
  }
}

.h-container {
  min-height: 40px;
  overflow-x: auto;
}

/*
  Pagination
*/
ul.pagination {
  .page-link {
    background: rgba(196, 196, 196, 0.4);
    margin: 0 2px;
    padding: 0.3rem 0.6rem;
    color: #91989e;
  }

  .page-item {
    &:first-child,
    &:last-child {
      .page-link {
        border-radius: 0;
      }
    }
  }
}

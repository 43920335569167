$dark-grey: #91989e;

.box-legend {
  color: $dark-grey;

  font-size: small;

  p {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}

.graph-subtitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
}

.box-white {
  background-color: white;
  padding: 0.5rem;
  margin: 0.5rem;

  .box-white-header {
    font-weight: bold;
    color: $dark-grey;
    font-size: 18px;
  }

  .box-white-icon {
    font-size: 19px;
  }

  .box-white-icon-check {
    color: white;
    background-color: #649bff;
    border-radius: 10px;
  }

  .box-white-icon-alert {
    color: white;
    background-color: #c4c4c4;
    border-radius: 10px;
  }

  .box-white-icon-warning {
    color: white;
    background-color: #ff9a89;
    border-radius: 10px;
  }

  .box-white-text {
    color: $dark-grey;
  }

  .box-white-legend {
    font-size: small;
    display: flex;
    justify-content: flex-end;
  }

  .box-white-legend-element {
    display: flex;
    align-items: center;

    h3 {
      font-size: small;
      margin-bottom: 0;
    }
  }
}

.box-grey-small {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  width: 100%;
  color: $dark-grey;

  h4 {
    font-size: 15px;
  }

  img {
    height: 30px;
  }
}

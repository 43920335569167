/*
    Albra
*/

@font-face {
  font-family: "Neutro";
  src: url("../fonts/Albra/BumbumType - Albra Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Albra";
  src: url("../fonts/Albra/BumbumType - Albra Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Albra";
  src: url("../fonts/Albra/BumbumType - Albra Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Albra";
  src: url("../fonts/Albra/BumbumType - Albra Semi.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Albra";
  src: url("../fonts/Albra/BumbumType - Albra Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Albra";
  src: url("../fonts/Albra/BumbumType - Albra Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

/*
    Neutro
*/

@font-face {
  font-family: "Neutro";
  src: url("../fonts/Neutro/Durotype - Neutro-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Neutro";
  src: url("../fonts/Neutro/Durotype - Neutro-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Neutro";
  src: url("../fonts/Neutro/Durotype - Neutro-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Neutro";
  src: url("../fonts/Neutro/Durotype - Neutro-Demi.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Neutro";
  src: url("../fonts/Neutro/Durotype - Neutro-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Neutro";
  src: url("../fonts/Neutro/Durotype - Neutro-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

/*
      Albra Text
*/
/*
@font-face {
  font-family: "Albra Text";
  src: url("../fonts/Albra Text Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Albra Text";
  src: url("../fonts/Albra Text Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Albra Text";
  src: url("../fonts/Albra Text Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Albra Text";
  src: url("../fonts/Albra Text Semi.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Albra Text";
  src: url("../fonts/Albra Text Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Albra Text";
  src: url("../fonts/Albra Text Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
*/

.accounts {
  .title {
    font-size: 24px;
    color: #91989e;
  }

  .hr {
    height: 2px;
    background-color: #91989e;
  }

  .subtitle {
    font-size: 16px;
    color: #91989e;
    margin-bottom: 1rem;
  }
}

.accounts-container {
  display: flex;
  align-items: flex-start;
  overflow: auto;
  white-space: nowrap;
  width: calc(100vw - 24rem);
  margin-bottom: 5rem;
  margin-right: -1.5rem;

  :last-child {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    width: 89vw;
  }
}

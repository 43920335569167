$dark-grey: #91989e;

.box-white {
  background-color: white;
  padding: 0.5rem;
  margin: 0.5rem;
  .box-white-icon {
    font-size: 19px;
  }

  .box-white-icon-check {
    color: white;
    background-color: hsl(219, 100%, 70%);
    border-radius: 10px;
  }

  .box-white-icon-alert {
    color: white;
    background-color: #c4c4c4;
    border-radius: 10px;
  }

  .box-white-icon-warning {
    color: white;
    background-color: #ff9a89;
    border-radius: 10px;
  }

  .box-white-text {
    color: $dark-grey;
  }

  .box-white-legend {
    font-size: small;
    display: flex;
    justify-content: flex-end;
  }

  .box-white-legend-element {
    display: flex;
    align-items: center;

    h3 {
      font-size: small;
      margin-bottom: 0;
    }
  }
}

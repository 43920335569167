.react-datepicker {
  background-color: #ff9a89;
  border: none;
  margin-top: 1rem;
}

.react-datepicker__header {
  background-color: #ff9a89;
  border-bottom: 1px solid white;
}

.react-datepicker__current-month {
  color: white;
}

.react-datepicker__day-name {
  color: white;
}

.react-datepicker__day {
  color: white;
}

.react-datepicker__day--selected {
  background-color: white !important;
  color: #ff9a89;
}

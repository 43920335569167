.box-grey {
  background: darken(#f9f9f9, 1%);
  padding: 15px 15px 30px 15px;
  height: 100%;

  .chart {
    display: flex;
    justify-content: center;
  }
}

.box-grey-title {
  color: #91989e;
  font-size: 19px;
  margin-bottom: 3px;
}

.box-grey-subtitle {
  color: #91989e;
  font-size: 12px;
}

.mobile-navigation-toggle {
  border-radius: 20px;
  background-color: #ff9a89 !important;
  border: none;
}

.mobile-navigation-toggle:after {
  display: none;
}

.mobile-navigation-menu {
  background-color: #e0ebff;
  width: 70vw;
}

.mobile-navigation-item {
  color: #649bff;
}
